body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root .navbar-link {
  color: rgba(0, 0, 0, 0.87) 
}

#root .navbar-link.active {
  color: rgb(25, 118, 210)
}

.loading-ellipsis:after {
display: inline-block;
animation: dotty steps(1,end) 1s infinite;
content: '';
}

@keyframes dotty {
0%   { content: ''; }
25%  { content: '.'; }
50%  { content: '..'; }
75%  { content: '...'; }
100% { content: ''; }
}